/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import "./layout.css"
import CookieConsent from "react-cookie-consent"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div
        id="content"
        className="site"
        style={{
          paddingTop: 0,
          display: `flex`,
          minHeight: `100vh`,
          flexDirection: `column`,
        }}
      >
        <Header siteTitle={data.site.siteMetadata.title} />
        <main
          style={{
            flexGrow: 1,
            padding: `0 1.0875rem 1.45rem`,
            margin: `0 auto`,
            maxWidth: 960,
          }}
        >
          {children}
        </main>
        <CookieConsent
          style={{
            background: `rgba(65, 104, 225, 0.88)`,
            fontSize: `16px`,
          }}
          buttonStyle={{
            backgroundColor: `white`,
            color: `#4168e1`,
            borderRadius: `5px`,
            margin: `2px 10px`,
            fontSize: `14px`,
          }}
          buttonText="I agree"
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
        <footer>
          © {new Date().getFullYear()}, JSON Shuffle
          <span style={{ padding: `0 0.5rem` }}>|</span>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <span style={{ padding: `0 0.5rem` }}>|</span>
          <Link to="/">Home</Link>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
