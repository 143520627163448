import React from "react"
import { ThemeToggler } from "gatsby-plugin-dark-mode"
import { FaMoon, FaSun, FaToggleOn, FaToggleOff } from "react-icons/all"

const DarkModeToggle = () => (
  <ThemeToggler>
    {({ theme, toggleTheme }) => (
      <div
        className="toggle-mode"
        title={`${theme === "dark" ? "Disable" : "Enable"} dark mode`}
        onClick={() => {
          const switchTo = theme === "dark" ? "light" : "dark"
          toggleTheme(switchTo)
        }}
      >
        <div className="icon">
          <FaSun />
        </div>
        <div className="toggle-switch">
          {theme === "dark" ? <FaToggleOn /> : <FaToggleOff />}
        </div>
        <div className="icon">
          <FaMoon />
        </div>
      </div>
    )}
  </ThemeToggler>
)

export default DarkModeToggle
