import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Image from "./image"
import DarkModeToggle from "./darkmode-toggle"

const Header = ({ siteTitle }) => (
  <header>
    <div className="headerContent">
      <div className="innerWrapper">
        <div className="appLogo">
          <Image />
        </div>
        <h1>
          <Link to="/">{siteTitle}</Link>
        </h1>
        <DarkModeToggle />
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
